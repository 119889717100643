<template>
  <div class="inline-flex !w-full items-center justify-center gap-x-2">
    <img
      src="~assets/img/supersizing/goboony.svg"
      class="h-16 w-16 md:h-20 md:w-20"
    >
    <YscIconsArrowTransfer class="h-8 w-8 md:h-10 md:w-10" />
    <img
      src="~assets/img/supersizing/yescapa.svg"
      class="h-16 w-16 md:h-20 md:w-20"
    >
  </div>
</template>
